<template>
  <section>
    <e-filters
      :searching="fetching"
      :title="$t('Busque pelos cupons fiscais que deseja incluir na Nota Fiscal')"
      :search-text="$t('Pesquisar')"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="order-id"
              v-model="saleInvoicesFilter.saleId"
              name="orderId"
              :label="$t('Id da venda')"
              type="text"
            />
          </b-col>
          <b-col md="6">
            <e-store-combo
              id="order-store_id"
              v-model="saleInvoicesFilter.storeId"
              :required="false"
            />
          </b-col>
          <b-col md="5">
            <e-person-search
              id="order-customer_id"
              v-model="saleInvoicesFilter.customerId"
              :label="$t('Cliente')"
              is-customer
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="period"
              v-model="saleInvoicesFilter.createdRangeDate"
              type="date-range-picker"
              :time-picker="false"
              :label="$t('Período')"
              :class="!saleInvoicesFilter.saleId ? 'required' : null"
              :validation="!saleInvoicesFilter.saleId ? 'requiredrange' : null"
              :validation-messages="{
                requiredrange: $t('Informe um período'),
              }"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="value"
              v-model="saleInvoicesFilter.value"
              :label="$t('Valor')"
              type="text-number"
              currency="R$"
              :precision="2"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-row>
      <b-col md="6">
        <b-card-actions
          action-refresh
          action-collapse
          :show-loading="fetching"
          @refresh="getData"
        >
          <div slot="title">
            <e-page-size-selector
              :per-page="paging.pageSize"
              @change="pageSizeChange"
            />
          </div>

          <h4>{{ $t('Cupons fiscais disponívels para incluir na Nota Fiscal') }}</h4>

          <b-table
            id="order-table"
            show-empty
            striped
            responsive
            class="bordered"
            :empty-text="
              searched
                ? $t('Nenhum cupom fiscal encontrado com os filtros selecionados')
                : $tc('tables.need_search')
            "
            :fields="fields"
            :items="availableSaleInvoices"
          >
            <template #cell(action)="row">
              <e-grid-actions
                :show-delete="false"
                :show-update="false"
                :buttons="buttons"
                @sale-added="addSaleInvoice(row.item)"
              />
            </template>
          </b-table>

          <b-row class="pt-1">
            <b-col cols="6">
              <e-pagination-summary
                :current-page="paging.currentPage"
                :per-page="paging.pageSize"
                :total="paging.rowCount"
                :total-on-page="paging.rowsInCurrentPage"
              />
            </b-col>
            <b-col cols="6">
              <b-pagination
                v-model="paging.currentPage"
                align="right"
                :total-rows="paging.rowCount"
                :per-page="paging.pageSize"
                aria-controls="order-table"
                @change="pageChange"
              />
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
      <b-col md="6">
        <b-card-actions
          action-refresh
          :show-loading="fetching"
          :title="$t('Cupons Fiscais Adicionados')"
          @refresh="getData"
        >
          <b-table
            id="invoice-table"
            show-empty
            striped
            responsive
            class="bordered"
            :empty-text="$t('Nenhum cupom fiscal adicionado')"
            :fields="fields"
            :items="saleInvoicesAdded"
          >
            <template #cell(action)="row">
              <e-grid-actions
                :show-delete="true"
                :show-update="false"
                @delete="removeSaleInvoice(row.index)"
              />
            </template>
          </b-table>
        </b-card-actions>
      </b-col>
    </b-row>

    <fab
      :main-tooltip="$t('Finalizar preenchimento da Nota Fiscal')"
      @click="onCreateInvoice"
    />
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary, EFilters, EGridActions } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { saleDomain, statusTypes, loadingOverlay } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import EPersonSearch from '@/views/components/inputs/EPersonSearch.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EFilters,
    EGridActions,
    EStoreCombo,
    EPersonSearch,
  },

  mixins: [statusTypes, saleDomain, loadingOverlay],

  data() {
    return {
      searched: false,
      fetching: false,
      returnItemsBusy: false,
    }
  },

  computed: {
    ...mapGetters('pages/invoice/invoice/invoiceMaintain', ['availableSaleInvoices']),
    ...mapState('pages/invoice/invoice/invoiceMaintain', [
      'saleInvoices',
      'saleInvoicesAdded',
      'paging',
      'saleInvoicesFilter',
    ]),
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Data'),
          key: 'issueDate',
          tdClass: 'text-center',
          thStyle: { width: '145px', minWidth: '140px' },
          formatter: val => this.$options.filters.datetime(val),
          sortable: true,
        },
        {
          label: this.$t('Id Venda'),
          key: 'saleId',
          tdClass: 'text-right',
          thStyle: { width: '80px' },
          sortable: true,
        },
        {
          label: this.$t('Loja'),
          key: 'store',
          tdClass: 'text-center',
          thStyle: { width: '30px' },
          formatter: val => val.id.toString().padStart(3, '0'),
        },
        {
          label: this.$t('Cliente'),
          key: 'customer',
          tdClass: 'text-left',
          formatter: val => val?.name || this.$t('Consumidor'),
          sortable: true,
        },
        {
          label: this.$t('Valor'),
          key: 'totalValue',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '130px' },
          formatter: val => this.$options.filters.currency(val),
          sortable: true,
        },
      ]
    },

    buttons() {
      return [
        {
          icon: 'plus',
          variant: 'outline-primary',
          title: this.$t('Adicionar Cupom Fiscal'),
          event: 'sale-added',
        },
      ]
    },
  },

  mounted() {
    // this.getData()
  },

  methods: {
    ...mapActions('pages/invoice/invoice/invoiceMaintain', [
      'createInvoiceFromSales',
      'fetchInvoiceFromSales',
      'cleanSaleInvoices',
      'addSaleInvoice',
      'removeSaleInvoice',
      'resetFilters',
      'setCurrentPage',
      'setPageSize',
    ]),

    async getData() {
      try {
        this.fetching = true
        await this.fetchInvoiceFromSales()
        this.searched = true
      } catch (error) {
        this.showGenericError({ error })
      } finally {
        this.fetching = false
      }
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    resetFiltersLocal() {
      this.resetFilters()
      this.setCurrentPage(1)
      this.getData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    async onCreateInvoice() {
      if (this.saleInvoicesAdded.length < 1) {
        this.showInvalidDataMessage({
          message: this.$t('INVOICE.IMPORT_FROM_SALE_ERROR.SALE_ID_LIST_IS_EMPTY'),
        })
        return
      }

      try {
        this.fetching = true
        await this.createInvoiceFromSales()
        this.$router.push({ name: 'invoice-create' })
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
  },
}
</script>

<style></style>
