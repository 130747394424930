var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "e-filters",
        {
          attrs: {
            searching: _vm.fetching,
            title: _vm.$t(
              "Busque pelos cupons fiscais que deseja incluir na Nota Fiscal"
            ),
            "search-text": _vm.$t("Pesquisar"),
          },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "order-id",
                          name: "orderId",
                          label: _vm.$t("Id da venda"),
                          type: "text",
                        },
                        model: {
                          value: _vm.saleInvoicesFilter.saleId,
                          callback: function ($$v) {
                            _vm.$set(_vm.saleInvoicesFilter, "saleId", $$v)
                          },
                          expression: "saleInvoicesFilter.saleId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("e-store-combo", {
                        attrs: { id: "order-store_id", required: false },
                        model: {
                          value: _vm.saleInvoicesFilter.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.saleInvoicesFilter, "storeId", $$v)
                          },
                          expression: "saleInvoicesFilter.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "5" } },
                    [
                      _c("e-person-search", {
                        attrs: {
                          id: "order-customer_id",
                          label: _vm.$t("Cliente"),
                          "is-customer": "",
                        },
                        model: {
                          value: _vm.saleInvoicesFilter.customerId,
                          callback: function ($$v) {
                            _vm.$set(_vm.saleInvoicesFilter, "customerId", $$v)
                          },
                          expression: "saleInvoicesFilter.customerId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        class: !_vm.saleInvoicesFilter.saleId
                          ? "required"
                          : null,
                        attrs: {
                          id: "period",
                          type: "date-range-picker",
                          "time-picker": false,
                          label: _vm.$t("Período"),
                          validation: !_vm.saleInvoicesFilter.saleId
                            ? "requiredrange"
                            : null,
                          "validation-messages": {
                            requiredrange: _vm.$t("Informe um período"),
                          },
                        },
                        model: {
                          value: _vm.saleInvoicesFilter.createdRangeDate,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.saleInvoicesFilter,
                              "createdRangeDate",
                              $$v
                            )
                          },
                          expression: "saleInvoicesFilter.createdRangeDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "value",
                          label: _vm.$t("Valor"),
                          type: "text-number",
                          currency: "R$",
                          precision: 2,
                        },
                        model: {
                          value: _vm.saleInvoicesFilter.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.saleInvoicesFilter, "value", $$v)
                          },
                          expression: "saleInvoicesFilter.value",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-card-actions",
                {
                  attrs: {
                    "action-refresh": "",
                    "action-collapse": "",
                    "show-loading": _vm.fetching,
                  },
                  on: { refresh: _vm.getData },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "title" }, slot: "title" },
                    [
                      _c("e-page-size-selector", {
                        attrs: { "per-page": _vm.paging.pageSize },
                        on: { change: _vm.pageSizeChange },
                      }),
                    ],
                    1
                  ),
                  _c("h4", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "Cupons fiscais disponívels para incluir na Nota Fiscal"
                        )
                      )
                    ),
                  ]),
                  _c("b-table", {
                    staticClass: "bordered",
                    attrs: {
                      id: "order-table",
                      "show-empty": "",
                      striped: "",
                      responsive: "",
                      "empty-text": _vm.searched
                        ? _vm.$t(
                            "Nenhum cupom fiscal encontrado com os filtros selecionados"
                          )
                        : _vm.$tc("tables.need_search"),
                      fields: _vm.fields,
                      items: _vm.availableSaleInvoices,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(action)",
                        fn: function (row) {
                          return [
                            _c("e-grid-actions", {
                              attrs: {
                                "show-delete": false,
                                "show-update": false,
                                buttons: _vm.buttons,
                              },
                              on: {
                                "sale-added": function ($event) {
                                  return _vm.addSaleInvoice(row.item)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "b-row",
                    { staticClass: "pt-1" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("e-pagination-summary", {
                            attrs: {
                              "current-page": _vm.paging.currentPage,
                              "per-page": _vm.paging.pageSize,
                              total: _vm.paging.rowCount,
                              "total-on-page": _vm.paging.rowsInCurrentPage,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("b-pagination", {
                            attrs: {
                              align: "right",
                              "total-rows": _vm.paging.rowCount,
                              "per-page": _vm.paging.pageSize,
                              "aria-controls": "order-table",
                            },
                            on: { change: _vm.pageChange },
                            model: {
                              value: _vm.paging.currentPage,
                              callback: function ($$v) {
                                _vm.$set(_vm.paging, "currentPage", $$v)
                              },
                              expression: "paging.currentPage",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-card-actions",
                {
                  attrs: {
                    "action-refresh": "",
                    "show-loading": _vm.fetching,
                    title: _vm.$t("Cupons Fiscais Adicionados"),
                  },
                  on: { refresh: _vm.getData },
                },
                [
                  _c("b-table", {
                    staticClass: "bordered",
                    attrs: {
                      id: "invoice-table",
                      "show-empty": "",
                      striped: "",
                      responsive: "",
                      "empty-text": _vm.$t("Nenhum cupom fiscal adicionado"),
                      fields: _vm.fields,
                      items: _vm.saleInvoicesAdded,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(action)",
                        fn: function (row) {
                          return [
                            _c("e-grid-actions", {
                              attrs: {
                                "show-delete": true,
                                "show-update": false,
                              },
                              on: {
                                delete: function ($event) {
                                  return _vm.removeSaleInvoice(row.index)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("fab", {
        attrs: {
          "main-tooltip": _vm.$t("Finalizar preenchimento da Nota Fiscal"),
        },
        on: { click: _vm.onCreateInvoice },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }